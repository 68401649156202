import { createSlice } from "@reduxjs/toolkit";
import { waitFor } from "../../app/appSlice";
import { assign, findIndices, searchRemoveAll } from "utils";

const widthToCeil = (width) => (width < 1050 ? 1 : width < 1800 ? 2 : 3);

const isMobileUserAgent =
  /Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const widthToIsMobile = (width) => isMobileUserAgent && width < 850;

const widthToIsMiddle = (width) => width < 1100;

const widthToIsTablet = (width) => width < 1400;

const sizeToIsLandscape = (width, height) =>
  height < 500 && height * 2 < width * 3;

export const MAIN = "main";
export const SIDE = "side";

const MAX = { [MAIN]: 2, [SIDE]: 1 };

export const PANE_DEBUG = { type: MAIN, pane: "debug" };

export const PANE_ZONES = { type: MAIN, pane: "zones" };

export const PANE_DOCS = { type: MAIN, pane: "docs" };
export const PANE_CHAT = { type: MAIN, pane: "chat" };
export const PANE_USERS = { type: MAIN, pane: "users" };
export const PANE_ADMIN = { type: MAIN, pane: "admin" };
export const PANE_DITTO = { type: MAIN, pane: "ditto" };

export const PANE_FILE = { type: SIDE, pane: "file" };
export const PANE_CHATS = { type: SIDE, pane: "chats" };
export const PANE_SEARCH = { type: SIDE, pane: "search" };
export const PANE_ARCHIVED = { type: SIDE, pane: "archived" };
export const PANE_TRANSFER = { type: SIDE, pane: "transfer" };
export const PANE_ACTIVITY = { type: SIDE, pane: "activity" };
export const PANE_SETTINGS = { type: SIDE, pane: "settings" };
export const PANE_ACCOUNT = { type: SIDE, pane: "account" };
export const PANE_USER = { type: SIDE, pane: "user" };

export const PANE_VIPS = ["docs", "chat", "admin"];

export const PANE_ORDER = [
  "debug",
  "docs",
  "search",
  "archived",
  "file",
  "transfer",
  "chat",
  "users",
  "chats",
  "activity",
  "settings",
  "account",
  "admin",
  "user",
];

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    width: window.innerWidth,
    height: window.innerHeight,
    ceil: widthToCeil(window.innerWidth),
    isMobile: widthToIsMobile(window.innerWidth),
    isMiddle: widthToIsMiddle(window.innerWidth),
    isTablet: widthToIsTablet(window.innerWidth),
    isLandscape: sizeToIsLandscape(window.innerWidth, window.innerHeight),
    showHeader: true,
    showFooter: false,
    paneList: [widthToIsMobile(window.innerWidth) ? PANE_ZONES : PANE_DOCS],
    paneAdded: "",
    paneRemoved: "",
  },
  reducers: {
    setWindowSize: (state, { payload: { width, height } }) => {
      state.width = width;
      state.height = height;
      state.ceil = widthToCeil(width);
      state.isMobile = widthToIsMobile(width);
      state.isMiddle = widthToIsMiddle(width);
      state.isTablet = widthToIsTablet(width);
      state.isLandscape = sizeToIsLandscape(width, height);
    },
    toggleFooter: (state) => {
      state.showFooter = !state.showFooter;
    },
    syncPanes: (state) => {
      state.paneAdded = "";
      state.paneRemoved = "";
    },
    setPanes: (state, { payload }) => {
      const { ceil, paneList: list } = state;
      const { pane, hide } = payload;
      if (hide) {
        if (list.length != 1 || list[0].pane != PANE_DOCS.pane) {
          searchRemoveAll(list, (item) => item.pane == pane.pane);
          state.paneRemoved = pane.pane;
          if (!list.length) {
            list.push(PANE_DOCS);
            state.paneAdded = PANE_DOCS.pane;
          }
        }
      } else {
        const present = list.slice(-ceil).find((x) => x.pane == pane.pane);
        if (present) {
          assign(present, pane);
        } else {
          let [removed] = findIndices(list, (item) => item.type == pane.type)
            .slice(MAX[pane.type] - 1)
            .flatMap((i, j) => list.splice(i - j, 1));
          list.push(pane);
          state.paneRemoved = removed?.pane ?? "";
          state.paneAdded = pane.pane;
        }
      }
      if (list.some((p) => p.pane == PANE_ADMIN.pane && !pane.logo)) {
        if (state.paneAdded == PANE_ADMIN.pane) {
          state.paneList = list.filter(
            (p) => p.pane == PANE_ADMIN.pane || p.pane == PANE_USER.pane
          );
        } else {
          state.paneList = list.filter(
            (p) => p.pane != PANE_ADMIN.pane && p.pane != PANE_USER.pane
          );
        }
        state.paneAdded = "";
        state.paneRemoved = "";
      }
      if (list.some((p) => p.pane == PANE_DITTO.pane)) {
        if (state.paneAdded == PANE_DITTO.pane) {
          state.paneList = list.filter((p) => p.pane == PANE_DITTO.pane);
        } else {
          state.paneList = list.filter((p) => p.pane != PANE_DITTO.pane);
        }
        state.paneAdded = "";
        state.paneRemoved = "";
      }
    },
  },
});

export const { syncPanes, toggleFooter, setWindowSize, setPanes } =
  layoutSlice.actions;

export const togglePane = (pane) => showPane(pane, true, true);

export const hidePane = (pane) => showPane(pane, true);

export const showPane = (pane, hide, auto) => async (dispatch, getState) => {
  const state = getState();
  const scope = state.layout;
  const inMotion = selectPanesInMotion(state);

  if (inMotion) {
    return;
  }

  if (auto) {
    hide = scope.paneList.slice(-scope.ceil).some((x) => x.pane == pane.pane);
  }

  dispatch(setPanes({ pane, hide }));
};

export const setWindowSizeNAdjustCeil = (payload) => (dispatch) => {
  dispatch(setWindowSize(payload));
};

export const selectWindowWidth = (state) => state.layout.width;
export const selectWindowHeight = (state) => state.layout.height;
export const selectPaneCeil = (state) => state.layout.ceil;
export const selectIsMobile = (state) => state.layout.isMobile;
export const selectIsMiddle = (state) => state.layout.isMiddle;
export const selectIsTablet = (state) => state.layout.isTablet;
export const selectIsLandscape = (state) => state.layout.isLandscape;
export const selectShowHeader = (state) => state.layout.showHeader;
export const selectShowFooter = (state) => state.layout.showFooter;
export const selectPaneList = (state) => state.layout.paneList;
export const selectPanes = (state) => state.layout.panes;
export const selectPaneAdded = (state) => state.layout.paneAdded;
export const selectPaneRemoved = (state) => state.layout.paneRemoved;
export const selectPanesInMotion = (state) =>
  !!(state.layout.paneAdded || state.layout.paneRemoved);
export default layoutSlice.reducer;
